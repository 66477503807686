import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';

const BlockPermissionModal = () => {
    
  return (
    <div className='modal-overlay'>
        <div className="modal text-left w-[300px] h-auto sm:w-[512px] sm:h-auto p-[40px] bg-white rounded-[24px] sm:rounded-[50px] flex items-center justify-center flex-col">
          <div>
            <h2 className='text-[28px] mb-5 text-center'><span className='block text-5xl mb-[10px] text-fuchsia'><FontAwesomeIcon icon={faVideoSlash} /></span>Camera Access Blocked</h2>
            <ul>
                <li><span className='w-[10px] h-[10px] inline-block bg-fuchsia rounded-full mr-[8px]'></span>Click on the camera icon in the browser's address bar.</li>
                <li><span className='w-[10px] h-[10px] inline-block bg-fuchsia rounded-full mr-[8px]'></span>To use this feature, please allow access to your camera.</li>
                <li><span className='w-[10px] h-[10px] inline-block bg-fuchsia rounded-full mr-[8px]'></span>Click on the camera icon in the browser's address bar.</li>
            </ul>
          </div>
          {/* <p className='py-5 text-[20px]'>Or</p> */}
          {/* <button className='bg-fuchsia hover:bg-pink-400 rounded-2xl h-[56px] sm:h-[64px] xl:h-[46px] text-white sm:text-2xl xl:text-[15px] uppercase font-bold px-[40px]'>Allow</button> */}
        </div>
    </div>
  )
}

export default BlockPermissionModal
