import React, { useEffect, useState, useRef } from 'react';
import plane from '../images/plane.png';

var webToken = localStorage.getItem('uuid');

const SendMessageForm = ({ addMessage, onFocusChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [message, setMessage] = useState('');
  const [textBoxFocus, setTextBoxFocus] = useState(false);
  const maxLength = 250;
  const textAreaRef = useRef(null); // Create a ref for the textarea

  const handleSubmit = async (e) => {
    if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
    e.preventDefault(); // Prevent form submission
    if (message.trim() !== '') {
      addMessage(message, 'sender'); // Indicate sender message
      try {
        const token = localStorage.getItem('token');
        const advisor_id = localStorage.getItem('advisor_id');
        const callId = localStorage.getItem('call_id');

        const selLang = localStorage.getItem('selectedLanguage');
        const url = `${process.env.REACT_APP_BASE_URL_API}call/chat/${callId}`;
        console.log(url);
        const fetchResponse = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
            'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
            Authorization: `Bearer ${token}`,
            locale: `${selLang}`,
            'WEB-TOKEN': webToken,
          },
          body: JSON.stringify({
            message: message,
            receiver_id: advisor_id, // Pass the selected category to the API
          }),
        });
        console.log(fetchResponse);
        if (!fetchResponse.ok) {
          throw new Error('Failed to register language selection');
        }
        const resData = await fetchResponse.json();
        console.log(resData);
        setMessage('');
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setTextBoxFocus(true);
        // // Focus the textarea after submitting the message
        // if (textAreaRef.current) {
        //   textAreaRef.current.focus();
        // }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // e.preventDefault();
      handleSubmit(e);
      setMessage('');
    }
  };
  
  const onFocusTextarea = () => {
    setIsFocused(true);
    onFocusChange(true);
    setTextBoxFocus(true);
  };
  const onBlurTextarea = () => {
    setIsFocused(false);
    onFocusChange(false); 
  }
  return (
    <>
      <form className="send-message-form md:mb-[0] h-[66px] rounded-[15px] border-[2px] border-[#7d6a551a]">
        <textarea
          ref={textAreaRef} // Pass the ref to the textarea
          className={
            textBoxFocus
              ? 'bg-transparent sm:p-[19px] p-[10px] h-full flex-1 text-[14px] sm:text-[18px] outline-none resize-none focused'
              : 'bg-transparent sm:p-[19px] p-[10px] sm:text-[18px] text-[14px] h-full flex-1 outline-none resize-none'
          }
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
          // Handle "Enter" key down
          onFocus={onFocusTextarea} onBlur={onBlurTextarea}
        />
        <button type="button" onClick={handleSubmit}>
          <img src={plane} className="w-[20px] h-[20px] mr-[20px] ml-[10px]" alt="Send Message" />
        </button>
      </form>
      <p className="text-sm text-gray-500 text-right">{Math.max(maxLength - message.length, 0)} characters remaining</p>
    </>
  );
};

export default SendMessageForm;
