import React from 'react'
// import logo from '../images/logoLight.png'

const LogoLight = () => {
  const logoSrc = localStorage.getItem('settingLogo'); 
  return (
    <div className='sm:w-[120px] w-[80px]'>
        <img src={logoSrc} alt="Changi Logo" />
    </div> 
  )
}

export default LogoLight
