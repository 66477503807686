import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageCompressor from 'image-compressor.js';
import { v4 as uuidv4 } from "uuid";
import loader from '../images/loader.gif'


const Screensaver = () => {
  const history = useNavigate();
  const [bgImg, setBgImg] = useState(null);
  const [loading, setLoading] = useState(true);
 
  useEffect(() => {
    // Generate UUID
    const uuid = uuidv4();

    if (!localStorage.getItem('uuid')) {              
      localStorage.setItem('uuid', uuid)
  }

    var savedUUID = localStorage.getItem('uuid');
    console.log('UUID saved to localStorage', savedUUID);
  }, []); 

  var savedUUID = localStorage.getItem('uuid');

  useEffect(() => {
    const fetchBgImg = async () => {
      try {
        
        const url = `${process.env.REACT_APP_BASE_URL_API}kiosk/${process.env.REACT_APP_DEVICE_ID}`;
        const response = await fetch(url);
        const data1 = await response.json();
        setBgImg(data1.data.kiosk_device.screensaver);

      } catch (error) {
        console.error('Error fetching background image data:', error);
      }
      finally {
        setLoading(false); // Set loading to false after image is fetched (or error occurs)
      }
    };
    const compressImage = (file) => {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: 0.6, // Adjust the quality as needed, between 0 and 1
          success: (compressedImage) => {
            resolve(compressedImage);
          },
          error: (e) => {
            console.error('Image compression error:', e);
            reject(e);
          },
        });
      });
    };
    const fetchGeneralSetting = async () => {
      try {
        const webtoken = localStorage.getItem('uuid');
        const settingUrl = `${process.env.REACT_APP_BASE_URL_API}general-setting`;
        console.log(settingUrl)
        const settingResponse = await fetch(settingUrl, {
          method: 'GET',
          headers: {
              'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
              'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
              'WEB-TOKEN' : webtoken
          },
        });
        const settingData = await settingResponse.json();
        const settingImg = settingData.data.company.queue_waiting_image;
        const settingLogo = settingData.data.company.logo;
        const showPopupTime = settingData.data.inactivity_popup_show_time;
        const dismissPopupTime = settingData.data.inactivity_popup_dismiss_time;

        // setLogoSrc(settingLogo);
        localStorage.setItem('settingImg', settingImg);
        localStorage.setItem('settingLogo', settingLogo);
        localStorage.setItem('inactivity_popup_show_time', showPopupTime)
        localStorage.setItem('inactivity_popup_dismiss_time', dismissPopupTime)

      } catch (error) {
        console.error('Error fetching background image data:', error);
      }
    };

    fetchBgImg();
    fetchGeneralSetting();

    const keysToPreserve = ['settingImg', 'uuid'];
    for (let key in localStorage) {
      if (!keysToPreserve.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  }, []);

  const onHandleClick = () => {
    history('/languages');
  };

  return (
    <div className='firstScreen xl:h-screen xl:min-h-screen h-screen cursor-pointer relative' onClick={onHandleClick} >
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
          <img src={loader} className='w-[60px]' alt='Loader' />
        </div>
      )}
      {!loading && bgImg && (
        <img src={bgImg} alt='Background' className='object-cover max-w-full h-full w-full' />
      )}
    </div>
  );
};

export default Screensaver;
