import React from 'react';
import LogoLight from './LogoLight';
import homeBtn from '../images/home.png';
import backBtn from '../images/back.png';
import { useNavigate } from 'react-router-dom';

const Header = ({ showBackButton, showHomeButton, handleClickGoBack, langCode, handleGoHome }) => {
  const nav = useNavigate();
  const currentPath = window.location.pathname;
  const handleGoBack = () => {
    handleClickGoBack(); // Invoke the callback function passed from the parent
  };
  // const handleGoBack = () => {
  //   if(currentPath === '/queue' || currentPath === '/categories' || currentPath === '/subcategory') {
  //     handleClickGoBack();
  //   } else {
  //     console.log("Fallback action triggered!");
  //     localStorage.removeItem('selectedCat');
  //     localStorage.removeItem('selectedSubCat');
  //     console.log("Navigating back using fallback method...");
  //     nav(-1);
  //   }
  // };

  const handleNavigateToHome = () => {
    if (handleGoHome) {
      handleGoHome(); // Invoke the GoHome function passed from the parent component
    } else {
      nav(`/${langCode ? langCode : ''}`);
    }
  };
  
  return (
    <>
      <div className='w-full bg-themeColor h-[60px] sm:h-[100px] relative'>
        {showBackButton && (
          <div className='cursor-pointer absolute left-4 bottom-4 sm:bottom-[17px] sm:left-15 xl:left-20' onClick={handleGoBack}>
            <img src={backBtn} className="sm:w-[166px] w-[80px] rounded-full transition-all btnEffect" alt="Go Back" />
          </div>
        )}
        <div className='flex justify-center items-center h-full'>
          <LogoLight />
        </div>
        {showHomeButton && (
          <div className='cursor-pointer absolute sm:bottom-[17px] sm:right-15 right-4 xl:right-20 bottom-4' onClick={handleNavigateToHome}>
            <img src={homeBtn} className="transition-all rounded-full homeBtn sm:w-[166px] w-[80px]" alt="Go to home" />
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
