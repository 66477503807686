import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CallConnect = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // alert('ok');
            navigate('/call');
        }, 3000);

        // Cleanup function to clear the timeout when component unmounts
        return () => clearTimeout(timeoutId);
    }, [navigate]);

    return (
        <div>
            <div className="waitingRoomDiv h-full w-full items-center justify-center min-h-screen flex absolute top-0 bg-white z-20 backdrop-blur-[87px]">
                <h1 className=' text-OyesterGrey font-bold text-center sm:text-[44px] text-[22px] px-[20px] sm:px-[100px]'>
                    {t('headings.queueConnect')}...
                </h1>
            </div>
        </div>
    );
}

export default CallConnect;
