import React, { useEffect } from 'react'
import thanksIcon from '../images/thanks.png'
import Header from '../Components/Header'
import Button from '../Components/Button'
import homeIcon from '../images/homeIcon.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


var webToken  = localStorage.getItem('uuid');
    const Thankyou = () => {
        // useEffect(() => {
        //     const disableBackButton = (e) => {
        //       e.preventDefault();
        //       window.history.forward();
        //     };
        
        //     window.history.pushState(null, null, window.location.pathname);
        //     window.addEventListener('popstate', disableBackButton);
        
        //     return () => {
        //       window.removeEventListener('popstate', disableBackButton);
        //     };
        //   }, []);
        // useEffect(() => {
        //     const handleBeforeUnload = (event) => {
        //       // Cancel the event
        //       event.preventDefault();
        //       // Chrome requires returnValue to be set
        //       event.returnValue = '';
        //       // Show a confirmation dialog
        //       const confirmationMessage = 'Are you sure you want to leave?';
        //       event.returnValue = confirmationMessage; // For Chrome
        //       return confirmationMessage;
        //     };
        
        //     window.addEventListener('beforeunload', handleBeforeUnload);
        
        //     return () => {
        //       window.removeEventListener('beforeunload', handleBeforeUnload);
        //     };
        //   }, []);
        //   useEffect(() => {
        //     const handleBeforeUnload = (event) => {
        //       // Cancel the event
        //       event.preventDefault();
        //       // Chrome requires returnValue to be set
        //       event.returnValue = '';
        //       // Show a confirmation dialog
        //       const confirmationMessage = 'Are you sure you want to leave?';
        //       event.returnValue = confirmationMessage; // For Chrome
        //       return confirmationMessage;
        //     };
          
        //     const handleUnload = () => {
        //       // Handle browser unload event
        //       console.log('Browser is closing');
        //     };
          
        //     window.addEventListener('beforeunload', handleBeforeUnload);
        //     window.addEventListener('unload', handleUnload);
          
        //     return () => {
        //       window.removeEventListener('beforeunload', handleBeforeUnload);
        //       window.removeEventListener('unload', handleUnload);
        //     };
        //   }, []);
        const { t } = useTranslation();        
        const customStyle = {
            display : 'flex',
            alignItems : 'center',
            borderRadius : '8px',
            justifyContent : 'center',
            padding: '0 20px',
            margin: 'auto'
        }
        const navigate = useNavigate()
        const goToHome = () => {
            navigate('/')
        }
        useEffect(() => {
          const fetchLogoutApi = async () => {
            try {
              const callId = localStorage.getItem('call_id')
              const token = localStorage.getItem('token');
              const selLang = localStorage.getItem('selectedLanguage');
              const logoutUrl = `${process.env.REACT_APP_BASE_URL_API}passenger/logout`;
              console.log(logoutUrl);
              const logoutResponse = await fetch(logoutUrl, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
                'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
                'Authorization': `Bearer ${token}`,
                'locale' :  `${selLang}`,
                'WEB-TOKEN' : webToken
                },
                body: JSON.stringify({
                    'call_id' : callId,
                }),
              })
              if (!logoutResponse.ok) {
                  throw new Error('Failed to register language selection');
              }
              // else {
              //   setTimeout(() => {
              //     window.location.replace('/thankyou')
              //   }, 500)
              // } 
              // setShowAdvisorName(true)
            }
            catch (error) {
              console.error('Error fetching data:', error);
            }
            finally { }
          }
          fetchLogoutApi();
        })
    return (
        <div className='screensaver'>
            <Header showHomeButton={false} />
            <div className='lg:w-[825px] md:w-[700px] sm:w-[600px] w-[300px] m-auto flex flex-col justify-center text-center mt-0 thankYou'>
                <div>
                    <h2 className='mt-[100px] xl:mt-[20px] text-OyesterGrey sm:text-[36px] text-[20px] xl:text-[18px] font-bold'>{t('headings.tyContent')}</h2>
                    <h3 className='mt-[10px] text-center text-[24px] xl:text-[20px] font-bold text-OyesterGrey'>{t('headings.thanks')}</h3>
                    <div className="lg:w-[598px] xl:w-[250px] 2xl:w-[350px] sm:w-[400px] w-[200px] h-auto m-auto mt-[20px]">
                        <img src={thanksIcon} alt="Thank you" className='w-full h-full' />
                    </div>
                </div>
                <div className='lg:mt-[150px] xl:mt-[20px] md:mt-[90px] mt-[50px] 2xl:mt-[30px]' onClick={goToHome}>
                    <Button label={t('headings.backToHome')} style={customStyle} icon={homeIcon} />
                </div>
            </div>
        </div>
    )
    }

    export default Thankyou
