import React, { useState, useEffect, useRef } from 'react';
import localCall from '../images/noCameraShowSmall.png';
import call from '../images/noCameraShow.png';
import chatIcon from '../images/chatIcon.png';
// import phoneIcon from '../images/phoneIcon.png';
import MessageList from './MessageList';
import SendMessageForm from './SendMessageForm';
import fullScreenIcon from '../images/fullscreen.png';
import { useNavigate } from 'react-router-dom';
import { DefaultDeviceController, MeetingSessionConfiguration, LogLevel, ConsoleLogger, DefaultMeetingSession } from 'amazon-chime-sdk-js';
import Pusher from 'pusher-js';
import { Audio } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
// import ConfirmBox from '../Components/ConfirmBox';
import Loader from '../images/loader.gif'

var webToken  = localStorage.getItem('uuid');

var videoInputDevices;
var meetingSession;
var localAttendeeId;
var clearSetTimeOut
var clearSetTimerOut


const VideoCall = () => {
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Cancel the event
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set
  //     event.returnValue = '';
  //     // Show a confirmation dialog
  //     const confirmationMessage = 'Are you sure you want to leave?';
  //     event.returnValue = confirmationMessage; // For Chrome
  //     return confirmationMessage;
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Cancel the event
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set
  //     event.returnValue = '';
  //     // Show a confirmation dialog
  //     const confirmationMessage = 'Are you sure you want to leave?';
  //     event.returnValue = confirmationMessage; // For Chrome
  //     return confirmationMessage;
  //   };
  
  //   const handleUnload = () => {
  //     // Handle browser unload event
  //     console.log('Browser is closing');
  //   };
  
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('unload', handleUnload);
  
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey || event.metaKey) && (event.key === 'u' || event.key === 'U' || (event.key === 'i' && event.shiftKey) || event.key === 'r' || event.key === 'R' ||event.key === 'F5')) {
          event.preventDefault();
      }
    };
    document.addEventListener('contextmenu', function(event) {
      event.preventDefault();
  });
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);
  const { t } = useTranslation();
  const [showChat, setShowChat] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const [localVideoResize, setLocalVideoResize] = useState(false);
  const [hideChatIcon, setHideChatIcon] = useState(false);
  const [changeFontSize, setChangeFontSize] = useState(false);
  const [fullScreenIconShow, setFullScreenIconShow] = useState(false);
  // const [showWaitingRoom, setShowWaitingRoom] = useState(false);
  const [showVideoRoom, setShowVideoRoom] = useState(false);
  const [showChatNotification, setShowChatNotification] = useState(false);
  const [lostConnection, setLostConnection] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false); // New state variable to track chat box status
  const [showEndButton, setShowEndButton] = useState(false);
  const [advisorName, setAdvisorName] = useState(null);
  // const [isMediaPermissionBlocked, setIsMediaPermissionBlocked] = useState(false);
  // const [isChatList, setIsChatList] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [shareScreenRemoteVideo, setShareScreenRemoteVideo] = useState(false)
  const [shareScreen, setShareScreen] = useState(false)
  const [showLocalVideo, setShowLocalVideo] = useState(false)
  const [showEndLoading, setShowEndLoading] = useState(false)
  const [showNotConnectEndLoading, setShowNotConnectEndLoading] = useState(false)
  
  var defaultTimer = localStorage.getItem('timeCounter') ? localStorage.getItem('timeCounter') : 60;
  const [timeCounter, setTimeCounter] = useState(defaultTimer);

  const [focused, setFocused] = useState('')

  const focusedTextarea = () => {
    setFocused(true)
  }
  
  useEffect(() => {
    let intervalId;
    if (showNotConnectEndLoading) {
      intervalId = setInterval(() => {
        // Update timeCounter state
        setTimeCounter(prevSeconds => {
          const newTimeCounter = prevSeconds - 1;
          // Update local storage
          localStorage.setItem('timeCounter', newTimeCounter.toString());
          return newTimeCounter;
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    // if(defaultTimer < 60) {
    //   setShowNotConnectEndLoading(true)
    // }
    return () => clearInterval(intervalId);
  }, [showNotConnectEndLoading]);
  
  useEffect(() => {
    if (timeCounter === 0 && showNotConnectEndLoading) {
      const fetchApi = async () => {
        try {
          const callId = localStorage.getItem('call_id')
          const token = localStorage.getItem('token');
          const selLang = localStorage.getItem('selectedLanguage');
          const logoutUrl = `${process.env.REACT_APP_BASE_URL_API}passenger/logout`;
          console.log(logoutUrl);
          const logoutResponse = await fetch(logoutUrl, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
            'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
            'Authorization': `Bearer ${token}`,
            'locale' :  `${selLang}`,
            'WEB-TOKEN' : webToken
            },
            body: JSON.stringify({
                'call_id' : callId,
            }),
          })
          if (!logoutResponse.ok) {
              throw new Error('Failed to register language selection');
          }
          else {
            setTimeout(() => {
              window.location.replace('/')
            }, 500)
          } 
        }
        catch (error) {
          console.error('Error fetching data:', error);
        }
        finally { }
      }
      fetchApi();
    }
  }, [timeCounter, showNotConnectEndLoading]);


  const onChatHandleClick = () => {
    setIsChatBoxOpen(true);
    setHideVideo(true);
    setShowChat(true);
    setShowChatNotification(false);
    setLocalVideoResize(true);
    setHideChatIcon(true);
    setChangeFontSize(true);
    setFullScreenIconShow(true);
  };

  const onFSClick = () => {
    setIsChatBoxOpen(false);
    setHideVideo(false);
    setShowChat(false);
    setLocalVideoResize(false);
    setHideChatIcon(false);
    setChangeFontSize(false);
    setFullScreenIconShow(false);
    setFocused(false)
  };

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      // navigate('/feedback')
    }, 20000);
  }, [navigate]);

  const [messages, setMessages] = useState([]);

  const addMessage = (sendMessage, sender) => {
    const currentSenderDate = new Date();
    const singaporeTime = currentSenderDate.toLocaleString('en-US', { timeZone: 'Asia/Singapore' });
    const senderMessage = { text: sendMessage, sender, date: singaporeTime };
    setMessages((prevMessages) => [...prevMessages, senderMessage]);
  };

  const receiveMessage = (message) => {
    const currentDate = new Date();
    const singaporeTime = currentDate.toLocaleString('en-US', { timeZone: 'Asia/Singapore' });
    const newMessage = { text: message, sender: 'receiver', date: singaporeTime };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    if (!isChatBoxOpen) { // Update notification only when chat box is closed
      setShowChatNotification(true);
    }
  };
  const history = useNavigate();
  useEffect(() => {
    const handlePopstate = () => {
      console.log('Popstate event triggered');
      console.log('Current pathname:', history.location.pathname);
      // Prevent redirection to the previous page if the current route is 'call'
      if (history.location.pathname === '/call') {
        console.log('Preventing redirection');
        history.push(window.location.pathname);
      }
    };

    // Add event listener for popstate event
    window.addEventListener('popstate', handlePopstate);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [history]);
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_CLUSTER
    });
    const callKey1 = localStorage.getItem('call_id');
    const userId = localStorage.getItem('user_id');
    console.log(userId);
    const globalChannel = pusher.subscribe(`kioskchat_${callKey1}`);

    const handleReceivedMessage = (data) => {
      console.log(data);
      if (userId === data.receiver_id) {
        console.log("userMessage", data);
        receiveMessage(data.message);
      }
    };

    globalChannel.bind('kioskchatevent', handleReceivedMessage);

    return () => {
      globalChannel.unbind('kioskchatevent', handleReceivedMessage);
      pusher.unsubscribe(`kioskchat_${callKey1}`);
    };
  }, [isChatBoxOpen]); // Add isChatBoxOpen as a dependency
  useEffect(() => {
    const fetchChatListData = async () => {
      try {
        const token = localStorage.getItem('token');
        const selLang = localStorage.getItem('selectedLanguage');
        const callID = localStorage.getItem('call_id');
        const chatListUrl = `${process.env.REACT_APP_BASE_URL_API}agent/chat-list/${callID}`;
        console.log('chat list' , chatListUrl)
        const fetchChatListResponse = await fetch(chatListUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
            'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
            'Authorization': `Bearer ${token}`,
            'locale': `${selLang}`,
            'WEB-TOKEN' : webToken
          }
        });
        if (!fetchChatListResponse.ok) {
          throw new Error('Failed to fetch chat list data');
        }
  
        // Parse response JSON
        const fetchChatListResponseData = await fetchChatListResponse.json();
        console.log('Type of fetchChatListResponseData.data:', typeof fetchChatListResponseData.data);
        // setIsChatList(fetchChatListResponseData.data.messages);
      } catch (error) {
        console.error('Error fetching chat list data:', error);
      }
    };
  
    fetchChatListData(); // Call the function to fetch chat list data
    const storedMessages = localStorage.getItem('chatMessages');
      if (storedMessages) {
        setMessages(JSON.parse(storedMessages));
      }
  }, []);
  useEffect(() => {
  // Save chat messages to localStorage whenever messages state changes
  localStorage.setItem('chatMessages', JSON.stringify(messages));
  scrollToBottom();
}, [messages]);
const scrollToBottom = () => {
  const messageList = document.getElementById('message-list');
  if (messageList) {
    messageList.scrollTop = messageList.scrollHeight;
  }
};
  useEffect(() => {
    let meetingData = '';
    let attendeeData = '';
    const initializeMeetingFunction = async () => {
      try {
        const callKey = localStorage.getItem('call_id');
        console.log(callKey);
        const token = localStorage.getItem('token');
        const selLang = localStorage.getItem('selectedLanguage');
        const url = `${process.env.REACT_APP_BASE_URL_API}create-attendee-token/${callKey}`;
        console.log('url', url);
        const fetchResponse = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
            'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
            'Authorization': `Bearer ${token}`,
            'locale': `${selLang}`,
            'WEB-TOKEN': webToken
          }
        })
        if (!fetchResponse.ok) {
          throw new Error('Failed to register language selection');
        }
        const resData = await fetchResponse.json();
        meetingData = resData.data.meeting
        attendeeData = resData.data.attendee
        const nameAd = resData.data.advsior.name;
        setAdvisorName(nameAd); 

        localStorage.setItem('advisor_name', resData.data.advsior.name);
        localStorage.setItem('advisor_id', resData.data.advisor_id);
        localStorage.setItem('advisor_img', resData.data.advsior.profilepic);

      }
      catch (error) {
        console.log('error')
      }
      finally { }
      const logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
      const deviceController = new DefaultDeviceController(logger);

      const configuration = new MeetingSessionConfiguration(meetingData, attendeeData);

      meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
      try {
        // Request both audio and video permissions
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
        const audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();
        videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
        if (audioInputDevices.length > 0) {
          await meetingSession.audioVideo.startAudioInput(audioInputDevices[0].deviceId);
        }
        else {
          console.log('Audio input device not found')
        }
        if (audioOutputDevices.length > 0) {
          await meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId);
        }
        else {
          console.log('Audio Output device not found')
        }
        if (videoInputDevices.length > 0) {
          await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
        }
        else {
          console.log('No video input device')
        }
      }
      catch (error) {
        // if (error.name === 'NotAllowedError' && error.message === 'Permission denied') {
        // } else {
        //   console.error('Error accessing audio and video devices:', error);
        // }

      }

      const getAudio = document.getElementById('myAudio')
      const getLocalVideo = document.getElementById('localVideo')
      const getShareScreenRemoteVideo = document.getElementById('shareScreenRemoteVideo')

      meetingSession.audioVideo.bindAudioElement(getAudio);
      const audioObserver = {
        audioVideoDidStart: () => {
          console.log('Started');
        }
      };
      // console.log('testing', audioObserver)
      meetingSession.audioVideo.addObserver(audioObserver);
      meetingSession.audioVideo.start();

      const attendeePresenceSet = new Set();
      const callback = (presentAttendeeId, present) => {
        console.log(`Attendee ID: ${presentAttendeeId} Present: ${present}`);
          let index = presentAttendeeId.indexOf("#content");
            if(localAttendeeId != presentAttendeeId) {
              if(presentAttendeeId) {
                setShowEndButton(false);
                setShowEndLoading(false);
                setShowNotConnectEndLoading(false)
                // alert('okokok')
                if(present){
                  setShowEndButton(false);
                  setShowEndLoading(false);
                  setShowNotConnectEndLoading(false)
                  console.log('showNotConnectEndLoading2', showNotConnectEndLoading)
                  setTimeCounter(defaultTimer)
                  // alert('oko')
                  clearTimeout(clearSetTimeOut);
                  clearTimeout(clearSetTimerOut)
                }else{

                   if (index !== -1  ) {
                    // alert('fine')
                      setShareScreen(false)
                      setShareScreenRemoteVideo(false)
                      setShowEndButton(false);
                      setShowEndLoading(false);
                      setShowLocalVideo(false);
                      setShowNotConnectEndLoading(false)
                      console.log('showNotConnectEndLoading1', showNotConnectEndLoading)
                      setTimeCounter(defaultTimer)
                    } else {
                        // alert('not fine')

                        setShowEndLoading(true);
                        clearSetTimerOut = setTimeout(() => {
                          setShowEndButton(true);

                          clearSetTimeOut = setTimeout(() => {
                            
                            setShowNotConnectEndLoading(true)
                            console.log('showNotConnectEndLoading', showNotConnectEndLoading)
                            setShowEndButton(false);
                            setShowEndLoading(false);
                          }, 120000)
                        }, 15000)

                        // if(showEndButton) {
                        //   alert('done')
                        //   setTimeout(() => {
                            
                        //     setShowNotConnectEndLoading(true)
                        //     console.log('showNotConnectEndLoading', showNotConnectEndLoading)
                        //     setShowEndButton(false);
                        //     setShowEndLoading(false);
                        //   }, 120000)
                        // }
                        // setTimeout(() => {
                        //   setShowNotConnectEndLoading(true)
                        //   console.log('showNotConnectEndLoading', showNotConnectEndLoading)
                        //   setShowEndButton(false);
                        //   setShowEndLoading(false);
                        // }, 120000)
                    }
                }
              }
              else {
                // alert('very fine')
                setShowEndLoading(true);
                setTimeout(() => {
                  setShowEndButton(true);
                }, 15000)
                setTimeout(() => {
                  setShowNotConnectEndLoading(true)
                  console.log('showNotConnectEndLoading3', showNotConnectEndLoading)
                  setShowEndButton(false);
                  setShowEndLoading(false);
                }, 20000)
            }
          }
        if (present) {
          attendeePresenceSet.add(presentAttendeeId);
          // console.log('Advisor in call')
          // setShowEndButton(false);
        } else {
          attendeePresenceSet.delete(presentAttendeeId);
          // setShowEndButton(true);
          // console.log('Advisor Left the call')
        }
      };
      // if(videoInputDevices.length > 0) {
      //   await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
      // }

      const videoObserver = {
        videoTileDidUpdate: tileState => {
          if (!tileState.boundAttendeeId || !tileState.localTile) {
             return;
          }
          localAttendeeId = tileState.boundAttendeeId
          meetingSession.audioVideo.bindVideoElement(tileState.tileId, getLocalVideo);
        }
      };
      meetingSession.audioVideo.addObserver(videoObserver);
      meetingSession.audioVideo.startLocalVideoTile();

      // setShowWaitingRoom(true);
      setShowVideoRoom(true);

      const getRemoteVideo = document.getElementById('remoteVideo')
      const remoteObserver = {
        videoTileDidUpdate: tileState => {
          if (!tileState.localTile && tileState.boundAttendeeId && tileState.tileId !== null) {
            console.log('RemoteUser',tileState);
           // meetingSession.audioVideo.bindVideoElement(tileState.tileId, getRemoteVideo);
           
           if(!tileState.localTile) {
            if(!tileState.isContent){
              if(tileState.active){
              }else{
                meetingSession.audioVideo.bindVideoElement(tileState.tileId, getRemoteVideo);
                console.log('show video ')
              }
             
            }
            else{
              setShareScreenRemoteVideo(true)

              setShareScreen(true)
              meetingSession.audioVideo.bindVideoElement(tileState.tileId, getShareScreenRemoteVideo);
              console.log('shared screen video')
              setShowLocalVideo(true);

            }
          }
            setShowLoading(true);
            setShowVideoRoom(true)
          }
        },

        videoTileWasRemoved: tileId => {

          console.log('remove video',tileId);
          const videoSources1 = meetingSession.audioVideo.getAllRemoteVideoTiles();
          // console.log("Remove video");
          console.log("removed dropped video");
        },
        remoteVideoSourcesDidChange: tileId => {
          const videoSources = meetingSession.audioVideo.getAllRemoteVideoTiles();
          // console.log("Video tile dropped:", tileId);
          console.log('Dropped Video', videoSources)
        }
      };
    
      console.log('meetingSession', meetingSession);
      meetingSession.audioVideo.addObserver(remoteObserver);
      const perceiver = {
        eventDidReceive(name, attributes) {
          console.log('Event name:', name);
          switch (name) {
            case 'signalingDropped':
              break;
            case 'meetingEnded':
              callEndClick();
              break;
            case 'receivingAudioDropped':
              console.log('Dropped connection')
              setLostConnection(true);
              break;
            case 'meetingReconnected':
              setLostConnection(true);
              break
              case 'audioVideoDidStop':
              console.log('Audio-Video connection stopped');
              break;
            default:
              break;
          }
        }
      }
      meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback);
      console.log('calling', meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback))
      meetingSession.audioVideo.addObserver(perceiver);
      console.log('perceiver', meetingSession.audioVideo.addObserver(perceiver))
    }
    initializeMeetingFunction();
  }, []);
  const componentRef = useRef(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const updateWindowHeight = () => {
      // Access the height of the window
      const newHeight = window.innerHeight - 200;
      // Update the state with the new height
      setHeight(newHeight);
    };
  
    // Call the function once initially to set the initial height
    updateWindowHeight();
  
    // Add an event listener to update the height whenever the window is resized
    window.addEventListener('resize', updateWindowHeight);
  
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowHeight);
    };
  }, [height]); 
    const callEndClick = async () => {
      meetingSession.audioVideo.stopVideoInput();
      meetingSession.audioVideo.stopAudioInput();
      meetingSession.audioVideo.stop();

    try {
      const callId = localStorage.getItem('call_id')
      const token = localStorage.getItem('token');
      const selLang = localStorage.getItem('selectedLanguage');
      const logoutUrl = `${process.env.REACT_APP_BASE_URL_API}passenger/logout`;
      console.log(logoutUrl);
      const logoutResponse = await fetch(logoutUrl, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
        'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
        'Authorization': `Bearer ${token}`,
        'locale' :  `${selLang}`,
        'WEB-TOKEN' : webToken
        },
        body: JSON.stringify({
            'call_id' : callId,
        }),
      })
      if (!logoutResponse.ok) {
          throw new Error('Failed to register language selection');
      }
      else {
        setTimeout(() => {
          window.location.replace('/feedback')
        }, 500)
      } 
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
    finally { }
  }
  return ( 
      <div className='screensaver'>
      <div className={showLoading ? "hidden" : "flex items-center justify-center h-screen absolute left-0 top-0 bg-black w-full"}>
        <img src={Loader} className='w-[60px]' alt='Loading' />
      </div>
      <div className={!showEndLoading ? "hidden" : "flex items-center flex-col justify-center h-screen absolute left-0 top-0 bg-black w-full z-10"}>
        <img src={Loader} className='w-[60px]' alt='Loading' />
        <h2 className='text-white text-[32px] font-bold'>Connecting...</h2>
        <div className={!showEndButton ? "hidden" : "block"}>
          <p className='text-white text-[20px] mb-[30px] text-center'>We're working to restore your connection. </p>
          <div className="bg-[#EE3424] text-white bottom-[100px] left-[200px] text-[24px] font-bold flex rounded-[15px] h-[82px] items-center justify-center cursor-pointer sm:w-[450px] m-auto shadow-[0px_3px_30px_0px_#00000026]" onClick={callEndClick}>
            END CALL
          </div>
        </div>
      </div>
      <div className={!showNotConnectEndLoading ? "hidden" : "flex items-center flex-col justify-center h-screen absolute left-0 top-0 bg-black w-full z-10"}>
        <img src={Loader} className='w-[60px]' alt='Loading' />
        <h2 className='text-white text-[32px] font-bold'>Connection unsuccessful</h2>
        <div>
          <p className='text-white text-[20px] mb-[30px] text-center'>This call will end in {timeCounter} seconds.</p>
          <div className="bg-[#EE3424] text-white bottom-[100px] left-[200px] text-[24px] font-bold flex rounded-[15px] h-[82px] items-center justify-center cursor-pointer sm:w-[450px] m-auto shadow-[0px_3px_30px_0px_#00000026]" onClick={callEndClick}>
            END CALL
          </div>
        </div>
      </div>
      <div className={!showVideoRoom ? "videoCallScreen hidden" : `block ${isChatBoxOpen ? "chatOpen" : `chatClose xl:h-screen`}`} style={{ height: `${height}px` }} ref={componentRef} >
        <audio id="myAudio" className='hidden'></audio>
        <div className={hideVideo ? `${isChatBoxOpen ? `showChat showChatProps ${focused ? "focusedTxtarea top-[0] transition-all" : "FocusOff top-[0]"}` : "hideChat"} sm:h-[320px] sm:w-[214px] xl:w-[300px] xl:h-[400px] w-[100%] h-[200px] fixed xl:absolute xl:top-[30px] xl:left-[30px] sm:top-[30px] sm:left-[30px] rounded-none sm:rounded-[15px] duration-700 overflow-hidden transition-all z-30 bg-black` : "videoDiv xl:w-auto w-screen xl:h-screen overflow-hidden bg-black block relative duration-700 top-0 left-0 transition-all"}>
          <img src={fullScreenIcon} className={fullScreenIconShow ? "absolute w-[20px] h-[20px] top-[10px] left-[10px] block z-40 cursor-pointer" : "hidden absolute w-[30px] h-[30px] top-[20px] left-[20px]"} onClick={onFSClick} alt="Icon" />
         
          <video src="" poster={call} id="remoteVideo" className={shareScreen ? ` bg-black border border-color-white screen-share ${isChatBoxOpen ? "chatOpen w-[30px] h-[40px] right-[5px] top-[5px] left-auto rounded-[5px] absolute" : "chatClose h-[160px] w-[100px] top-[10px] left-[10px] sm:h-[240px] absolute sm:top-[30px] sm:left-[30px] right-auto sm:w-[180px] rounded-[15px] object-contain pb-[0]"}` : "h-full w-full rounded-[15px] object-contain pb-[58px] screen-not-share"}></video>
          <video src="" poster={localCall} id="localVideo" className={localVideoResize ? 
            `md:h-[74px] md:w-[55px] absolute border rounded-[4px] md:top-[18px] w-[40px] h-[50px] object-contain top-[10px] right-[10px] md:right-[18px] transition-all bg-black ${showLocalVideo ? "showLocalVideo hidden" : "hideLocalVideo block"}` : 
            `transition-all sm:h-[242px] w-[120px] h-[180px] object-contain bg-black sm:w-[182px] absolute border rounded-[15px] top-[10px] right-[10px] sm:top-[30px] sm:right-[50px] xl:top-[30px] xl:right-[30px] md:top-[30px] md:right-[30px] ${showLocalVideo ? "showLocalVideo hidden" : "hideLocalVideo block" }`} autoPlay></video>
          <video src="" poster={call} id="shareScreenRemoteVideo" className={shareScreenRemoteVideo ? `h-full w-full xl:w-[86%] ml-auto rounded-[15px] object-contain pb-[58px] block ${isChatBoxOpen ? "w-full xl:w-full" : "chatband hai"}` : "hidden"}></video>

          <div className={hideChatIcon ? "hidden" : "absolute bottom-[70px] left-[15px] sm:bottom-[100px] sm:left-[100px] bg-white rounded-full sm:w-[90px] sm:h-[90px] h-[60px] w-[60px] z-10 flex items-center justify-center shadow-[0px_4.219px_42.187px_0px_rgba(0, 0, 0, 0.15)] cursor-pointer"} onClick={onChatHandleClick}>
            <img src={chatIcon} alt="Chat" className='sm:w-[34px] w-[24px]' />
            <span className={showChatNotification ? "block w-[17px] h-[17px] bg-crimsonRed rounded-full absolute top-[10px] right-[10px] sm:right-[17px] sm:top-[18px]" : " hidden"}></span>
          </div>
          <div className='absolute bg-fuchsia py-1 w-full bottom-0'>
            <h6 className={changeFontSize ? "text-white text-center sm:text-[22px] text-[18px] capitalize" : "text-white text-center text-[20px] sm:text-[32px] capitalize"}> {advisorName}</h6>
          </div>
        </div>
        <div className={showChat ?
            "chat-box flex flex-col justify-between items-stretch px-[30px] xl:pr-[30px] xl:pl-0 sm:pr-[50px] sm:pl-[100px] md:px-[100px] py-[30px] sm:py-[50px] bg-white transition-all duration-200 w-[100%] sm:w-[75%] ml-auto" :
            "hidden"}>
                <MessageList messages={messages} key={messages} id="message-list"  /> 
                <SendMessageForm addMessage={addMessage} onFocusChange={focusedTextarea} />
          </div>
      </div>
      <div className={lostConnection ? "text-center bg-black flex flex-col items-center h-screen justify-center absolute top-0 z-[99] w-full" : "hidden"}>
        <Audio height="60" width="60" radius="9" color="green" ariaLabel="loading" wrapperStyle wrapperClass />
        <p className='text-white text-[32px] font-bold'>{t('headings.lostInternet')}</p>
      </div>
      {/* <div className={showWaitingRoom ? "hidden w-full h-full waitingRoomDiv items-center justify-center min-h-screen" : "waitingRoomDiv h-full w-full items-center justify-center min-h-screen flex absolute top-0 bg-white z-20 backdrop-blur-[87px]"}>
        <h1 className=' text-OyesterGrey font-bold text-center sm:text-[44px] text-[22px] px-[20px] sm:px-[100px]'>
          {t('headings.queueConnect')}
        </h1>
      // </div> */}
    </div>
  );
};

export default VideoCall;
