import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../lang/en.json';
import translationHI from '../lang/hi.json';
import translationZH from '../lang/zh.json';
import translationRU from '../lang/ru.json';
import translationJA from '../lang/ja.json';
import translationKO from '../lang/ko.json';
// Function to get the language from local storage or default to 'en'
const getLanguageFromLocalStorage = () => {
  const savedLanguage = localStorage.getItem('selectedLanguage'); 
  console.log(savedLanguage)
  // Use getItem to retrieve from localStorage
  return savedLanguage || 'en'; // If savedLanguage is null or undefined, return 'en' as default language

};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      zh: {
        translation: translationZH,
      },
      hi: {
        translation: translationHI,
      },
      ja: {
        translation: translationJA,
      },
      ko: {
        translation: translationKO,
      },
      ru: {
        translation: translationRU,
      },
    },
    lng: getLanguageFromLocalStorage(), // Set language from local storage
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
