import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Modal from '../Components/Modal';
import homeIcon from '../images/darkHome.png';
import { useNavigate } from 'react-router-dom';
import queueImg from '../images/queueImage.png';
import { useTranslation } from 'react-i18next';
import Pusher from 'pusher-js';
import Loader from '../images/loader.gif';
import { useParams } from 'react-router-dom';

var webToken  = localStorage.getItem('uuid');

const Waiting = () => {
    const { langCode, categoryId } = useParams();
    useEffect(() => {
        console.log('Language Code:', langCode);
        console.log('Category ID:', categoryId);
    })
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_CLUSTER
    });

    useEffect(() => {
        const glabalChannel = pusher.subscribe(process.env.REACT_APP_KIOSK_ID);
        glabalChannel.bind('refreshqueue',function(data) {
            console.log('QueueDetail', data)
            const temp_Callid2 = localStorage.getItem('temp_CallId');
            if(temp_Callid2 == data.call_id) {
                localStorage.setItem('queueNo', data.queueNo);
                setShowQueueNo(data.queueNo || "");
            }
        });
    }, [pusher]);

    useEffect(() => {
        const disableBackButton = (e) => {
            e.preventDefault();
            window.history.forward();
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', disableBackButton);

        return () => {
            window.removeEventListener('popstate', disableBackButton);
        };
    }, []);

    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showWaitingRoom, setShowWaitingRoom] = useState(false);
    const [addQueueClass, setAddQueueClass] = useState(false);
    const [showQueueNo, setShowQueueNo] = useState("");
    const [dynamicQueueImg, setDynamicQueueImg] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const queueNumber = localStorage.getItem('queueNo');
        setShowQueueNo(queueNumber || "");
        const showWaitingImg = localStorage.getItem('waitingImg');
        setDynamicQueueImg(showWaitingImg);
        setLoading(false);
        let timeoutId;
        // timeoutId = setTimeout(() => {
        //     // setAddQueueClass(true);
        //     // setShowWaitingRoom(true);            
        // }, 5000); 
        // Set timeout to 5 seconds

        return () => clearTimeout(timeoutId);
    }, [setShowWaitingRoom, setAddQueueClass]);

    const navigate = useNavigate();

    const GoBackHome = async () => {
        try {
            const token = localStorage.getItem('token');
            const selLang = localStorage.getItem('selectedLanguage');
            const tempID = localStorage.getItem('temp_CallId');

            const logoutUrl = `${process.env.REACT_APP_BASE_URL_API}passenger/logout`;
            console.log(logoutUrl);
            const logoutResponse = await fetch(logoutUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
                    'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
                    'Authorization': `Bearer ${token}`,
                    'locale' :  `${selLang}`,
                    'WEB-TOKEN' : webToken
                },
                body: JSON.stringify({
                    'call_id' : tempID,  // Pass the selected category to the API
                }),
            });
            // console.log(logoutResponse)
            if (!logoutResponse.ok) {
                throw new Error('Failed to register language selection');
            } else {
                navigate('/');
            } 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const GoBack = () => {
        setIsModalOpen(true);
    };

    const GoHome = () => {
        setIsModalOpen(true); // Open the modal when home is clicked
    };

    // Stop refresh page
    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if Ctrl key is pressed and the key is 'r'
            if (event.ctrlKey && event.key === 'r') {
                event.preventDefault(); // Prevent the default behavior (refresh)
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    if (loading) {
        return (
            <div className='h-screen flex items-center justify-center text-3xl text-OyesterGrey'>
                <img src={Loader} className='w-[70px]' alt="Loading..." />
            </div>
        );
    }

    return (
        <div className='relative screensaver'>
            <div className={addQueueClass ? "h-screen overflow-hidden opacity-40" : "" }>
                <Header showHomeButton={true} showBackButton={true} handleClickGoBack={GoBack} handleGoHome={GoHome} />
                <div className='sm:mx-[80px] mx-[40px] md:mx-0' style={{height: 'calc(100vh - 170px)'}}>
                    <div className='flex items-center justify-center mt-12'>
                        <h2 className='sm:text-4xl text-OyesterGrey text-center font-bold'>{t('headings.queueHeading')}</h2>
                    </div>
                    <div className='w-full relative xl:flex xl:gap-10 xl:max-w-[1280px] m-auto xl:mt-11'>
                        <div className='relative lg:w-[645px] md:w-[500px] m-auto flex-1'>
                            <img src={queueImg} className='lg:h-[458px] xl:h-[352px] md:h-[300px] w-full mt-[20px] sm:mt-[50px] mx-auto' alt="Queue" />
                            <div className='queueNo absolute left-[50%] top-[50%] md:-mt-[20px] lg:-mt-[40px] xl:mt-[0] md:text-center -mt-[20px]'>
                                <h2 className='mb-3 md:mb-2 text-white text-[24px] sm:text-[28px]'>{t('headings.queueNo')}</h2>
                                <h1 className='text-yellow lg:text-[120px] text-[50px] -mt-6 font-bold text-center'>
                                    {showQueueNo}
                                </h1>
                            </div>
                        </div>

                        <p className=' text-OyesterGrey text-[16px] sm:text-[24px] xl:hidden block font-normal text-center my-10 sm:my-16'>{t('headings.queueWait')}...</p>
                        <div className='lg:max-w-[824px] sm:m-auto lg:my-[36px] md:max-w-[500px] xl:m-x-auto xl:mt-[50px] xl:mb-12 flex-1'>
                            <p className=' text-OyesterGrey text-[24px] hidden xl:block font-normal text-left mt-0'>{t('headings.queueWait')}...</p>
                            {dynamicQueueImg && <img src={dynamicQueueImg} className='rounded-none mt-[20px]' alt="Banner" />}
                        </div>
                        <Modal isOpen={isModalOpen} popupImage={homeIcon} BackToHome={GoBackHome} closeModal={closeModal} popupLargeText={t('headings.returnHome')} popupSmallText={t('headings.popupSmallContent')} btnDarkLabel={t('headings.popupButtonDarkContent')} btnLightLabel={t('headings.returnHome')}  />
                    </div>
                </div>
            </div>
            <div className={showWaitingRoom ? "waitingRoomDiv h-full w-full items-center justify-center min-h-screen flex absolute top-0 bg-white backdrop-blur-[87px]" : "w-full h-full waitingRoomDiv items-center justify-center min-h-screen hidden"}>
                <h1 className=' text-OyesterGrey font-bold text-center sm:text-[44px] text-[22px] px-[20px] sm:px-[100px]'>
                    {t('headings.queueConnect')}...
                </h1>
            </div>
        </div>
    );
};

export default Waiting;
