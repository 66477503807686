
import React from 'react';

const Button = ({ onClick, label, icon, style, showImage }) => {
  return (
    <div>
      <button onClick={onClick} style={style} className=' bg-fuchsia rounded-2xl h-[56px] sm:h-[64px] xl:h-[46px] xl:w-[200px] sm:w-[350px] w-full text-white sm:text-2xl xl:text-[15px] uppercase font-bold px-[40px]'>
        {showImage && <img src={icon} alt="Icon" className='mr-5 w-[32px]' />}
        {label}
      </button>
    </div>
  );
};

export default Button;
