import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import senderIcon from '../images/sender.png';

const MessageList = ({ messages }) => {
    const messageListRef = useRef();

    const adImg = localStorage.getItem('advisor_img');
    const adName = localStorage.getItem('advisor_name');


    // Set scroll to bottom when component mounts
    useEffect(() => {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }, [messages]);

    return (
        <div className="message-list flex-col flex justify-end">
            <div className='overflow-y-auto overflow-x-hidden customScrollbar pr-[20px]' ref={messageListRef}>
                {messages.map((message, index) => (
                    <div key={index} className={`message flex mb-[20px] last:mb-0 ${message.sender}`}>
                        <div className="message-content max-w-[450px] min-w-[112px] break-words " style={{ marginLeft: message.sender === 'sender' ? 'auto' : '0' }}>
                            <div className="message-text px-[30px] py-[20px] rounded-[33px] rounded-bl-[0px]" style={{ backgroundColor: message.sender === 'sender' ? '#fff' : '#0082720d', borderRadius: message.sender === 'sender' ? '33px 33px 0px 33px' : '33px 33px 33px 0px' }}>
                                <div className='text-OyesterGrey text-[16px] tsm:ext-[20px]'>{message.text}</div>
                            </div>
                            <div className="flex mt-2" style={{ justifyContent: 'space-between', flexDirection: message.sender === 'sender' ? 'row' : 'row-reverse' }}>
                                <div className="opacity-[0.72] text-[12px] text-OyesterGrey">{moment(message.date).format('h:mm a')}</div>
                                
                                <div className='flex items-center'>
                                    {message.sender === 'sender' && (
                                        <>
                                            <div className="message-sender mr-1 text-[12px] text-OyesterGrey opacity-[0.72] ">{message.name || 'You'}</div>
                                            <div className="sender-image">
                                                <img src={senderIcon} className='h-[20px] w-[20px] rounded-full' alt="Sender" />
                                            </div>
                                        </>
                                    )}
                                    {message.sender === 'receiver' && (
                                        <>
                                            <div className="receiver-image">
                                                <img src={adImg} className='h-[20px] w-[20px] rounded-full' alt="Receiver" />
                                            </div>
                                            <div className="message-sender ml-1 text-[12px] text-OyesterGrey">{adName || 'Advisor'}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MessageList;
