import React from 'react'
import logo from '../images/logo.png'

const Logo = () => {
  return (
    <>
        <div>
            <img src={logo} className=' h-24 sm:h-28' alt="Changi Logo" />
        </div> 
    </>
  )
}

export default Logo
