import React, {useEffect} from 'react';
//Pusher
import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';
import CallConnect from '../Pages/CallConnect';

function PusherFunction() {
	const turnOffCamera = async () => {
		let localStream; // Declare localStream variable globally

			navigator.mediaDevices.getUserMedia({
			audio: true,
			video: true
			})
			.then(stream => {
			localStream = stream; // Assign the stream to the global variable

			// Automatically stop camera tracks after 10 seconds
			setTimeout(() => {
				if (localStream) {
				localStream.getVideoTracks().forEach(track => {
					track.stop();
				});
				}
			}, 2000); // 10 seconds delay
			
			})
			.catch((err) => {
			console.log(err);
			});
	  };
	//This will be called when your component is mounted
    const navigate = useNavigate();
	useEffect(() => {
		const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
			cluster: process.env.REACT_APP_CLUSTER
		})
		const glabalChannel = pusher.subscribe(process.env.REACT_APP_KIOSK_ID);

		glabalChannel.bind('callAccepted',function(data) {
			const temp_Callid = localStorage.getItem('temp_CallId');
			if(temp_Callid == data.call_id) {
				console.log(data.call_id)
				localStorage.setItem('call_id', data.call_id);
				navigate('/callConnect');
			}
		})
		glabalChannel.bind('callEnded',function(data) {
			const temp_Callid1 = localStorage.getItem('temp_CallId');
			if(temp_Callid1 == data.call_id) {
				console.log(data.call_id)
				const getCallId = localStorage.getItem('call_id');
				if(getCallId == data.call_id) {
					turnOffCamera();	
					window.location = '/feedback'			
				}
			}
		})
		return (() => {
		})
	}, []);


	return(
		<div>
			{/* Render Code here */}
		</div>
	)
}


export default PusherFunction;


