import React from 'react'

const ModalButton = ({onClick, label, icon, showImage }) => {
  return (
    <div>
       <button onClick={onClick} className=' bg-fuchsia hover:bg-[#e97ab3] transition-all rounded-2xl h-[56px] sm:h-[64px] w-[250px] sm:w-[350px] text-white text-[15px] sm:text-2xl uppercase font-bold px-[40px]'>
        {showImage && <img src={icon} alt="Icon" className='mr-5 w-[32px]' />}
        {label}
      </button>
    </div>
  )
}

export default ModalButton  