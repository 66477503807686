import React, { useState, useEffect } from 'react';
import MessageList from './MessageList';
import SendMessageForm from './SendMessageForm';



const ChatBox = () => {
  const [messages, setMessages] = useState([]);

  const addMessage = (message, sender) => {
    const newMessage = { text: message, sender, date: new Date() };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const receiveMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, { text: message, sender: 'receiver', date: new Date() }]);
  };

  useEffect(() => {
    // Simulate receiving a message after sending
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.sender === 'sender') {
      setTimeout(() => {
        receiveMessage("Thanks for your message!");
      }, 1000); // Adjust the delay as needed
    }
  }, [messages]);

  return (
    <div className="chat-box h-screen flex flex-col justify-between items-stretch px-[50px] sm:px-[100px] py-[50px] bg-chatBg">
      <MessageList messages={messages} />
      <SendMessageForm addMessage={addMessage} />
    </div>
  );
};

export default ChatBox;
