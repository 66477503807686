import React, { useState, useEffect } from 'react';
import ButtonTransparent from './ButtonTransparent';
import ModalButton from './ModalButton';

const Modal = ({ isOpen, popupImage, popupLargeText, popupSmallText, btnDarkLabel, btnLightLabel, closeModal, BackToHome }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setModalOpen(false); // Reset state when the component unmounts
    };
  }, []);

  return (
    <>
      {modalOpen && (
        <div className={`modal-overlay ${isOpen ? 'animate-[fadeIn_0.3s_ease-out]' : 'animate-[fadeOut_0.3s_ease-in]'}`}>
          <div className="modal w-[300px] h-auto sm:w-[512px] sm:h-[533px] bg-white rounded-[24px] sm:rounded-[50px] flex items-center justify-center flex-col px-[20px] sm:px-[55px]">
            <img src={popupImage} className='block m-auto mt-10 mb-3 w-[50px] sm:w-[97px]' alt="Icon" />
            <h2 className="text-center sm:text-[32px] text-[20px] font-bold mb-[10px] sm:mb-[20px] text-OyesterGrey sm:leading-[38px]">{popupLargeText} ?</h2>
            <h3 className="sm:text-[20px] text-[16px] text-OyesterGrey font-normal pb-[30px] text-center">{popupSmallText}</h3>
            
            {/* Pass showImage prop as false to hide the image */}
            <ModalButton label={btnDarkLabel} onClick={closeModal} showImage={false} />
            <ButtonTransparent btnLabel={btnLightLabel} goBacKHomePage={BackToHome} />
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
