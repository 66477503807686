import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

const PermissionModal = ({handleClick}) => {
    
  return (
    <div className='modal-overlay'>
        <div className="modal text-left w-[300px] h-auto sm:w-[512px] sm:h-auto p-[40px] bg-white rounded-[24px] sm:rounded-[50px] flex items-center justify-center flex-col">
          <div>
          <h2 className='sm:text-[28px] text-[20px] mb-8'>Changi is blocked from using your video and microphone</h2>
          <ul className='list-decimal pl-[12px]'>
             <li className='text-[16px] sm:text-[20px] mt-[10px] text-[#666]'>Click the setting icon <span className='text-[16px] mb-[10px] px-2'><FontAwesomeIcon icon={faSliders} /></span> in your browser's address bar</li>
             <li className='text-[16px] sm:text-[20px] mt-[10px] text-[#666]'>Clear blocked video and microphone permissions</li>
             <li className='text-[16px] sm:text-[20px] mt-[10px] text-[#666]'>Then click on reload button</li>
          </ul>
          

          </div>
          <p className='py-5 text-[20px]'>Or</p>
          <button onClick={handleClick} className='bg-fuchsia hover:bg-pink-400 rounded-2xl h-[56px] sm:h-[64px] xl:h-[46px] text-white sm:text-2xl xl:text-[15px] uppercase font-bold px-[40px]'>Allow</button>
        </div>
    </div>
  )
}

export default PermissionModal
