import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatBox from './Pages/ChatBox';
import Connect from './Pages/Connect';
import SelectLanguage from './Pages/SelectLanguage';
import SelectCategory from './Pages/SelectCategory';
import Waiting from './Pages/Waiting';
import VideoCall from './Pages/VideoCall';
import Feedback from './Pages/Feedback';
import Thankyou from './Pages/Thankyou';
import SubCategory from './Pages/SubCategory';
import ActionWeb from './Pages/ActionWeb';
import PusherSocket from './Components/PusherSocket';
import { I18nextProvider } from 'react-i18next';
import i18n from './Pages/i18n'; // Import i18n configuration
import CallConnect from './Pages/CallConnect';
import Screensaver from './Pages/Screensaver';

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <PusherSocket />
        <Routes>
          <Route path="/Connect" element={<Connect />} />  
          <Route path="/" element={<Screensaver />} />  
          <Route path="/languages" element={<SelectLanguage />} />  
          <Route path="/:langCode/categories" element={<SelectCategory />} />  
          <Route path="/:langCode/category/:categoryId/queue" element={<Waiting />} />
          <Route path="/call" element={<VideoCall />} />  
          <Route path='/chatBox' element={<ChatBox />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/thankyou' element={<Thankyou />} />
          <Route path='/subCategories' element={<SubCategory />} />
          <Route path='/webView' element={<ActionWeb />} />
          <Route path='/callConnect' element={<CallConnect />} />
        </Routes>
      </Router>  
    </I18nextProvider>
  );  
}

export default App;
