import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import veryPoor from '../images/veryPoor.png';
import poor from '../images/poor.png';
import neutral from '../images/neutral.png';
import veryGood from '../images/veryGood.png';
import excellent from '../images/excellent.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



var webToken  = localStorage.getItem('uuid');

const Feedback = () => {
  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);
  const { t } = useTranslation();
  const [showAdvisorName, setShowAdvisorName] = useState(localStorage.getItem('advisor_name'));
  useEffect(() => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);

      window.location.replace('/');
    }, 10000); 
    const resetTimeout = () => {
      clearTimeout(timeout);
      setTimeout(() => {
        window.location.replace('/');
      }, 10000); 
    };

    document.addEventListener('click', resetTimeout);
    document.addEventListener('mousemove', resetTimeout);
    document.addEventListener('keypress', resetTimeout);

    return () => {
      document.removeEventListener('click', resetTimeout);
      document.removeEventListener('mousemove', resetTimeout);
      document.removeEventListener('keypress', resetTimeout);
      clearTimeout(timeout);
    };
  }, []);
const icons = [
  {
    "id": 1,
    "icon": veryPoor,
    "name": t('headings.veryPoor'),
    "value": 1
  },
  {
    "id": 2,
    "icon": poor,
    "name": t('headings.poor'),
    "value": 2
  },
  {
    "id": 3,
    "icon": neutral,
    "name": t('headings.neutral'),
    "value": 3
  },
  {
    "id": 4,
    "icon": veryGood,
    "name": t('headings.veryGood'),
    "value": 4
  },
  {
    "id": 5,
    "icon": excellent,
    "name": t('headings.excellent'),
    "value": 5
  }
];
  // const navigate = useNavigate();

  const handleClick = async (event) => {
    const callId = localStorage.getItem('call_id')
    console.log(callId)
    const value = event.currentTarget.getAttribute('data-value');
    console.log('Clicked value:', value);
    try {
      const token = localStorage.getItem('token');
      const selLang = localStorage.getItem('selectedLanguage');
      const url = `${process.env.REACT_APP_BASE_URL_API}rating/${callId}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
          'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
          'Authorization': `Bearer ${token}`,
          'locale' :  `${selLang}`,
          'WEB-TOKEN' : webToken
        },
        body: JSON.stringify({
          'rating' : value,
        }),
      })
      console.log('fetchRes', response);
      if (!response.ok) {
        throw new Error('Failed to fetch category data');
      }
      else {
        // setTimeout(() => {
          window.location.replace('/thankyou')
        // }, 50)
      }
      await response.json();
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
    finally { }
  };

  return (
    <>
      <div className='h-screen screensaver'>
        <Header showHomeButton={false} />
        <div className='lg:w-[825px] md:w-[700px] sm:w-[600px] w-[310px] m-auto flex flex-col text-center mt-[30px] sm:mt-0 justify-start md:justify-center thankYou'>
          <h3 className='mt-0 text-center sm:text-[24px] text-[18px] text-OyesterGrey'>
            {t('headings.feedBackSmallContent', { advisorName: showAdvisorName || '' })}
          </h3>
          <h2 className='mt-[10px] text-OyesterGrey sm:text-[36px] text-[20px] font-bold'>{t('headings.feedBackContent')}</h2>
          <div className='flex flex-wrap sm:justify-between justify-center mt-[60px]'>
            {
              icons.map((val) => {
                return (
                  <div key={val.id} data-value={val.value} className='md:w-[124px] sm:w-[90px] w-[80px] sm:m-0 m-[10px] h-auto cursor-pointer' onClick={handleClick}>
                    <img src={val.icon} alt={val.name} />
                    <p className=' text-OyesterGrey font-bold text-[16px] sm:text-[20px] mt-[10px]'>{val.name}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Feedback;
