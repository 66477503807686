import React, { useState, useEffect } from 'react'
import Header from '../Components/Header'
import { useNavigate } from 'react-router-dom'
import clockIcon from '../images/clock.png'
import Modal from '../Components/Modal';
import { useTranslation } from 'react-i18next';
import Loader from '../images/loader.gif'

const webToken  = localStorage.getItem('uuid');

const SubCategory = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = '';
      // Show a confirmation dialog
      const confirmationMessage = 'Are you sure you want to leave?';
      event.returnValue = confirmationMessage; // For Chrome
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = '';
      // Show a confirmation dialog
      const confirmationMessage = 'Are you sure you want to leave?';
      event.returnValue = confirmationMessage; // For Chrome
      return confirmationMessage;
    };
  
    const handleUnload = () => {
      // Handle browser unload event
      console.log('Browser is closing');
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);
  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);
  const { t, i18n } = useTranslation();
  // useEffect(() => {
  //   // Reset language to default language when component mounts
  //   i18n.changeLanguage('defaultLanguage');
  // }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subCatCompanyCategory, setSubCatCompanyCategory] = useState(null);
  const [catTitle, setCatTitle] = useState([0]);

  const [catactiveId, setCatActiveId] = useState(() => {
    return parseInt(localStorage.getItem('selectedCat') || 0);
  });


const fetchData = async () => {
  try {
      const token = localStorage.getItem('token');
      const selLang = localStorage.getItem('selectedLanguage');
      let selCat = localStorage.getItem('selectedCat');
      if(localStorage.getItem('selectedSubCat')){
          selCat = localStorage.getItem('selectedSubCat');
      }
     
      const url = `${process.env.REACT_APP_BASE_URL_API}request-call`;
      console.log(url);
      const fetchResponse = await fetch(url, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
          'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
          'Authorization': `Bearer ${token}`,
          'locale' :  `${selLang}`,
          'WEB-TOKEN' : webToken
          },
          body: JSON.stringify({
              'category_id' : selCat,  // Pass the selected category to the API
          }),
          
      })
      console.log(fetchResponse)
      if (!fetchResponse.ok) {
          throw new Error('Failed to register language selection');
      }
      const resData = await fetchResponse.json();
      localStorage.setItem('temp_CallId', resData.data.call_id)
      console.log(resData.data.queue_no)
      const queueNo = resData.data.queue_no;
      const waitingImg = resData.data.company.queue_waiting_image;
      localStorage.setItem('queueNo', queueNo);
      localStorage.setItem('waitingImg', waitingImg);
      console.log(queueNo);
      console.log(waitingImg);
      navigate('/queue')
  }
  catch (error) {
      console.error('Error fetching data:', error);
  }
  finally {
      setLoading(false);
  }
}
  useEffect(() => {
    const fetSubCategory = async () => {
        try {
            const token = localStorage.getItem('token');
            const selLang = localStorage.getItem('selectedLanguage');
            const subCatUrl = `${process.env.REACT_APP_BASE_URL_API}company-categories/${catactiveId}?locale=${selLang}`;
            const subCatResponse = await fetch(subCatUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
                    'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
                    'Authorization': `Bearer ${token}`,
                    'locale': `${selLang}`,
                    'WEB-TOKEN': webToken
                },
            });

            if (!subCatResponse.ok) {
                throw new Error('Failed to fetch subcategories');
            }

            const subCatData = await subCatResponse.json();
            console.log('API Response:', subCatData);

            // Ensure sub_category is not null or undefined
            if (subCatData?.data[0]?.sub_category) {
                setSubCatCompanyCategory(subCatData.data[0].sub_category);
                setCatTitle(subCatData.data[0].title);
                console.log('Subcategories:', subCatData.data[0].sub_category);
            } else {
                // console.error('Subcategories not found in API response:', subCatData);
               setTimeout( async () => {
                await fetchData();
               }, 100)
            }

            setCatActiveId(catactiveId);
            // setTimeout(() => {
            //     localStorage.setItem('selectedCat', catactiveId);
            // }, 500);
        } catch (error) {
            console.error('Error making API request:', error);
        } finally {
            setLoading(false);
        }
    };

    fetSubCategory();
}, [catactiveId]);

  const navigate = useNavigate();
  const handleRedirectClick = (id) => {
    const selectedCategory = subCatCompanyCategory.find(catVal => catVal.category_id === id);
    console.log(selectedCategory);
    localStorage.setItem('selectedSubCat', id);
    if (selectedCategory) {
      const actionType = selectedCategory.action_type;
      console.log('Action Type:', actionType); // Add this console log
      const actionFile = selectedCategory.action_file;
      if (actionFile && actionType != null) { 
        setTimeout(() => {
          if (actionType === '1' || actionType === '2' || actionType === '3' || actionType === '4') {
            window.open(actionFile, '_blank');
          } else if (actionType == 5) {
            console.log('Navigating to waiting page...'); // Add this console log
            setTimeout( async () => {
              await fetchData();
            }, 500)
          } else {
            setTimeout( async () => {
              await fetchData();
            }, 500)
          }
        }, 500);
      } 
      // Now you can use actionType and actionFile as needed
      console.log('Action File:', actionFile);
    }
    setCatActiveId(id);
  }

  const GoHome = () => {
    console.log("Return home function called");
    navigate('/')
  }
  const goBackHome = () => {
    setTimeout(() => {
      navigate('/');
    }, 50)
  }
  // Show and hide popup
  
  const hideInactivePopup = parseInt(localStorage.getItem('inactivity_popup_dismiss_time')) * 1000;
  const [countdown, setCountdown] = useState(hideInactivePopup / 1000);
    
    const closeModal = (redirect) => {
      setModalOpen(false);
      if (redirect) {
        navigate('/');
      } else {
        setCountdown(0);
      }
    };
  useEffect(() => {
      let timer;
      
      if (modalOpen && countdown > 0) {
        timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 50); // decrement countdown every second
      }
  
      return () => {
        clearInterval(timer);
      };
    }, [modalOpen, countdown]);
  
    useEffect(() => {
      if (countdown === 0) {
        closeModal(); // Close modal when countdown reaches 0
      }
    }, [countdown, closeModal]);
  useEffect(() => {
    const showInactivePopup = parseInt(localStorage.getItem('inactivity_popup_show_time')) * 1000;
  
    let inactivityTimer;
    let closeTimer;

    const resetTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
      inactivityTimer = setTimeout(() => {
        setModalOpen(true);
        closeTimer = setTimeout(() => {
          setModalOpen(false);
          setTimeout(() => {
            navigate('/');
          }, 50)
        }, hideInactivePopup);
      }, showInactivePopup);
    };

    const clearTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
    };

    const handleActivity = () => {
      resetTimers();
    };

    // Initial setup
    resetTimers();

    // Event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Clean-up
    return () => {
      clearTimers();
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);
   // Stop refresh page
   const GoBack = () => {
    navigate('/categories')
    localStorage.removeItem('selectedLanguage');
    i18n.changeLanguage('defaultLanguage'); 
  }
   useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl key is pressed and the key is 'r'
      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault(); // Prevent the default behavior (refresh)
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  if (loading) {
    return <div className='h-screen flex items-center justify-center text-3xl text-OyesterGrey'>
       <img src={Loader} className='w-[70px]' alt="Loading..." />
    </div>
  }
  return (
    <div className='screensaver'>
      <Header showBackButton={true} showHomeButton={true} handleGoHome={GoHome} handleClickGoBack={GoBack} />
      <div className='flex items-center justify-center mt-12'>
        <span className='sm:text-4xl text-2xl text-OyesterGrey font-bold'>
          {catTitle ? catTitle : "No title"}
        </span>
      </div>
      <div className='flex justify-center flex-wrap gap-3 sm:gap-8 lg:max-w-[824px] xl:max-w-[1280px] mt-[20px] sm:mt-[70px] md:max-w-auto m-auto mb-8'>
        {
          subCatCompanyCategory.map((catVal) => {
            return (
              <div key={catVal.category_id} onClick={() => handleRedirectClick(catVal.category_id)} data-actiontype={catVal.action_type} data-actionfile={catVal.action_file} className={catactiveId === catVal.category_id ?
                "select-none selectedCat pointer-events-none sm:w-[40%] xl:w-[30%] w-[40%] h-auto sm:min-h-[265px] border border-lightPink sm:rounded-[34px] rounded-[12px] bg-fuchsia cursor-pointer transition-all" :
                "select-none sm:w-[40%] xl:w-[30%] h-auto  w-[40%] sm:min-[265px] border border-lightPink hover:bg-pink-100 transition-all sm:rounded-[34px] rounded-[12px] cursor-pointer"}>
                <div className='w-full h-full py-5  sm:py-10  flex items-center justify-center flex-col sm:px-7 text-center break-all'>
                  <img src={catVal.icon} className='sm:w-[96px] sm:h-[96px] w-[40px] h-[40px]' alt={catVal.title} />
                  <p className={`${catactiveId === catVal.category_id ? " text-white" : " text-OyesterGrey"} sm:text-xl xl:text-2xl font-normal mt-[8px] px-[8px] sm:mt-[30px] text-[14px] md:text-2xl leading-[normal]`}>
                    {catVal.title}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
      <Modal 
        BackToHome={goBackHome} 
        closeModal={() => closeModal(false)} 
        isOpen={modalOpen} 
        popupImage={clockIcon} 
        popupLargeText={t('headings.langPopupLargeText')} 
        popupSmallText={`Continue browsing or be redirected to the home page in ${countdown} seconds.`} 
        btnDarkLabel={t('headings.continueBrowsing')} 
        btnLightLabel={t('headings.returnHome')}>
      </Modal>
    </div>
  )
}

export default SubCategory