import React from 'react'

const ButtonTransparent = ({btnLabel, goBacKHomePage}) => {
  return (
    <>
      <button onClick={goBacKHomePage} className='bg-transparent text-OyesterGrey hover:text-[#958d84] transition-all w-full justify-center text-[15px] sm:text-2xl uppercase font-bold flex sm:h-[82px] h-[64px] mb-[10px] sm:rounded-2xl px-8 py-1 sm:py-5 items-center'>
           {btnLabel}
        </button> 
    </>
  )
}

export default ButtonTransparent
