import React, { useState, useEffect } from 'react'
import Header from '../Components/Header'
import { useNavigate } from 'react-router-dom'
import clockIcon from '../images/clock.png'
import Modal from '../Components/Modal';
import { useTranslation } from 'react-i18next'; 
import Loader from '../images/loader.gif'
import PermissionModal from '../Components/PermissionModal';
import BlockPermissionModal from '../Components/BlockPermissionModal';


var webToken = localStorage.getItem('uuid');

const SelectCategory = () => {
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [blockPermissionModal, setBlockPermissionModal] = useState(false);


  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);
  const { t } = useTranslation();
  // useEffect(() => {
  //   // Reset language to default language when component mounts
  //   i18n.changeLanguage('defaultLanguage');
  // }, []);

  console.log('Translated Heading:', t('headings.categoryHeading'));
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyCategory, setCompanyCategory] = useState([0]);
  const [catactiveId, setCatActiveId] = useState(() => {
    return parseInt(localStorage.getItem('selectedCat') || 0);
  });
  // const selLang = localStorage.getItem('selectedLanguage');
  
  
  const navigate = useNavigate(); 
  const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const selLang = localStorage.getItem('selectedLanguage');
        let selCat = localStorage.getItem('selectedCat');
        if(localStorage.getItem('selectedSubCat')){
            selCat = localStorage.getItem('selectedSubCat');
        }
       
        const url = `${process.env.REACT_APP_BASE_URL_API}request-call`;
        console.log(url);
        const fetchResponse = await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
            'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
            'Authorization': `Bearer ${token}`,
            'locale' :  `${selLang}`,
            'WEB-TOKEN' : webToken
            },
            body: JSON.stringify({
                'category_id' : selCat,  // Pass the selected category to the API
            }),
            
        })
        console.log(fetchResponse)
        if (!fetchResponse.ok) {
            throw new Error('Failed to register language selection');
        }
        const resData = await fetchResponse.json();
        // console.log(resData.data)
        localStorage.setItem('temp_CallId', resData.data.call_id)
        const queueNo = resData.data.queue_no;
        const waitingImg = resData.data.company.queue_waiting_image;
        localStorage.setItem('queueNo', queueNo);
        localStorage.setItem('waitingImg', waitingImg);
        console.log(queueNo);
        console.log(waitingImg);
        navigate(`/${selLang}/category/${selCat}/queue`); 
        // navigate('/queue')
    }
    catch (error) {
        console.error('Error fetching data:', error);
    }
    finally {
        setLoading(false);
    }
}
  
  const handleRedirectClick = async (id) => {
    console.log("Handling redirect for category ID:", id);
    localStorage.setItem('selectedCat', id);
    const getSubCat = JSON.parse(localStorage.getItem('categroyJSON'));
    
    for (const category of getSubCat) {
      if (category.category_id === id) {
        console.log("Category found:", category);
        setCatActiveId(id);
        
        if (category.sub_category && category.sub_category.length > 0) {
          console.log("Subcategories found:", category.sub_category);
          setTimeout(() => {
            navigate('/subCategories'); 
          }, 50)
        } else {
          console.log("No subcategories found, navigating based on action type.");
          // If no subcategories, navigate based on action type
          const actionType = category.action_type;
          const actionFile = category.action_file;
          
          if (actionFile && actionType != null) {
            if (actionType === '1' || actionType === '2' || actionType === '3' || actionType === '4') 
              console.log('actionType is 1');
              window.open(actionFile);
            }
             else if (actionType === '5') {
              console.log('actionType is 5, redirecting to waiting');
              if (navigator.userAgent.indexOf("Firefox") != -1) {
                    console.log("This is Mozilla Firefox.");
                    try {
                      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio : true });
                        // Permission granted
                        await fetchData();
                        console.log('Camera access granted.');
                        setShowPermissionModal(false);
                        setBlockPermissionModal(false);
                        stream.getTracks().forEach(track => track.stop()); // Stop the stream after permission check
                      } else {
                        console.log('getUserMedia not supported.');
                        // Fallback behavior if getUserMedia is not supported
                      }
                    } catch (error) {
                      console.log('Error while handling camera permission:', error);
                      // Handle errors
                      setBlockPermissionModal(true);
                    }
                } 
                // Check if the browser is Google Chrome
                else if ((navigator.userAgent.indexOf("Chrome") != -1) ||  (navigator.userAgent.indexOf("Safari") !== -1 && navigator.vendor && navigator.vendor.indexOf("Apple") !== -1)) {
                    console.log("This is Google Chrome.");
                    try {
                      const permissionStatus = await navigator.permissions.query({ name: 'camera' });
                      const microphonePermissionStatus = await navigator.permissions.query({ name: 'microphone' });
                      if (permissionStatus.state === 'granted') {
                          if(microphonePermissionStatus.state === 'granted') {
                            await fetchData();
                            console.log('microphone access granted.');
                            setShowPermissionModal(false);
                            setBlockPermissionModal(false)
                          }else if(microphonePermissionStatus.state === 'denied') {
                            setBlockPermissionModal(true)
                            console.log('microphone access denied.');
      
                          }
                          else if(microphonePermissionStatus.state === 'prompt') {
                            console.log('microphone access prompt.');
                            setShowPermissionModal(true);
                            setBlockPermissionModal(false);
                            localStorage.setItem('tempCatID',  localStorage.getItem('selectedCat'))
                            localStorage.removeItem('selectedCat');
                          }
                      } 
                      else if (permissionStatus.state === 'denied') {
      
                        console.log('Camera access denied.');
                        
                        try {
                          const devices = await navigator.mediaDevices.enumerateDevices();
                          const videoDevices = devices.filter(device => device.kind === 'videoinput' && !device.label);
                          if (videoDevices.length > 0) {
                            // console.log('Camera is blocked');
                            setBlockPermissionModal(true);
                            setShowPermissionModal(false);
                            localStorage.setItem('tempCatID',  localStorage.getItem('selectedCat'))
                            localStorage.removeItem('selectedCat');
                          } else {
                            await fetchData();
                          }
                        } catch (error) {
                          console.error('Error while handling camera permission:', error);
                        }
                      } 
                      else if (permissionStatus.state === 'prompt') {
                        console.log('Camera access prompt.');
                        setShowPermissionModal(true);
                        setBlockPermissionModal(false)
                        localStorage.setItem('tempCatID',  localStorage.getItem('selectedCat'))
                        localStorage.removeItem('selectedCat');
                      }
                    }
                    catch(error) {
                      console.error('Error', error)
                    }
                } 
                // If it's not Firefox or Chrome, it could be another browser
                else {
                    try {
                      const permissionStatus = await navigator.permissions.query({ name: 'camera' });
                      const microphonePermissionStatus = await navigator.permissions.query({ name: 'microphone' });
                      if (permissionStatus.state === 'granted') {
                          if(microphonePermissionStatus.state === 'granted') {
                            await fetchData();
                            console.log('microphone access granted.');
                            setShowPermissionModal(false);
                            setBlockPermissionModal(false)
                          }else if(microphonePermissionStatus.state === 'denied') {
                            setBlockPermissionModal(true)
                            console.log('microphone access denied.');
      
                          }
                          else if(microphonePermissionStatus.state === 'prompt') {
                            console.log('microphone access prompt.');
                            setShowPermissionModal(true);
                            setBlockPermissionModal(false);
                            localStorage.setItem('tempCatID',  localStorage.getItem('selectedCat'))
                            localStorage.removeItem('selectedCat');
                          }
                      } 
                      else if (permissionStatus.state === 'denied') {
      
                        console.log('Camera access denied.');
                        
                        try {
                          const devices = await navigator.mediaDevices.enumerateDevices();
                          const videoDevices = devices.filter(device => device.kind === 'videoinput' && !device.label);
                          if (videoDevices.length > 0) {
                            // console.log('Camera is blocked');
                            setBlockPermissionModal(true);
                            setShowPermissionModal(false);
                            localStorage.setItem('tempCatID',  localStorage.getItem('selectedCat'))
                            localStorage.removeItem('selectedCat');
                          } else {
                            await fetchData();
                          }
                        } catch (error) {
                          console.error('Error while handling camera permission:', error);
                        }
                      } 
                      else if (permissionStatus.state === 'prompt') {
                        console.log('Camera access prompt.');
                        setShowPermissionModal(true);
                        setBlockPermissionModal(false)
                        localStorage.setItem('tempCatID',  localStorage.getItem('selectedCat'))
                        localStorage.removeItem('selectedCat');
                      }
                    }
                    catch(error) {
                      console.error('Error', error)
                    }
                
                    console.log("This is a different browser.");
                }
            }
            else {
            console.log("No specific action type found, redirecting to waiting by default.");
            try {
              const devices = await navigator.mediaDevices.enumerateDevices();
              const videoDevices = devices.filter(device => device.kind === 'videoinput' && !device.label);
              if (videoDevices.length > 0) {
                console.log('Camera is blocked');
                setShowPermissionModal(true);
                localStorage.removeItem('selectedCat');
              } else {
                await fetchData();
              }
            } catch (error) {
              console.error('Error while handling camera permission:', error);
            }
          }
        }
        return; // Exit the loop once the category is found
      }
    }
  };
  useEffect(() => {
    // console.log('navigator', navigator.userAgent)
    if (navigator.userAgent.indexOf("Chrome") != -1) {
      // console.log("This is Google Chrome.");
      const ftchApi = async () => {
        const permissionStatusOnLoad = await navigator.permissions.query({ name: 'camera' });
        const microphonePermissionStatusOnLoad = await navigator.permissions.query({ name: 'microphone' });
        if (permissionStatusOnLoad.state === 'granted' && microphonePermissionStatusOnLoad.state === 'granted') {
          const tempId = localStorage.getItem('tempCatID')
            
              if(tempId) {
                await handleRedirectClick(tempId);
                await fetchData();
              }
            }
          }
        
          ftchApi();
  } 
  }, [])
const handleGoClick = () => {
  navigator.mediaDevices.getUserMedia({ video: true, audio: true })
  .then(async function (stream) {
      const tempCategory = localStorage.getItem('tempCatID')
      await handleRedirectClick(tempCategory);
      await fetchData();
  }).catch(function (error) {
      console.log('Error accessing camera:', error.name, error.message);
      // Provide specific error handling for different cases
      if (error.name === 'NotAllowedError') {
          console.log('User denied access to camera or microphone.');
      } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
          console.log('No camera or microphone found.');
      } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
          console.log('Camera or microphone is already in use or not available.');
      } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
          console.log('Constraints could not be satisfied by available devices.');
      } else if (error.name === 'SecurityError' || error.name === 'NotAllowedError') {
          console.log('Media support is not allowed or blocked by the user.');
      } else {
          console.log('Unknown error occurred:', error);
      }
  });
}
  const GoHome = () => {
    console.log("Return home function called");
    navigate('/')
  }
  const goBackHome = () => {
    setTimeout(() => {
      navigate('/');
    }, 100)
  }
  useEffect (() => {
    const fetchCategoryData = async () => {
      try {
        const token = localStorage.getItem('token');
        const selLang = localStorage.getItem('selectedLanguage');
          const categoryUrl = `${process.env.REACT_APP_BASE_URL_API}company-categories?limit=`;
          const categoryResponse = await fetch(categoryUrl, {
            method: 'GET',
            headers: {
              'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
              'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
              'Authorization': `Bearer ${token}`,
              'locale' :  `${selLang}`,
              'WEB-TOKEN' : webToken
            },
          });
          if (!categoryResponse.ok) {
            throw new Error('Failed to fetch category data');
          }
          const catResponseData = await categoryResponse.json();
          localStorage.setItem('categroyJSON', JSON.stringify(catResponseData.data));
          setCompanyCategory(catResponseData.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchCategoryData();
  }, [])
  const GoBack = () => {
    navigate('/languages')
   localStorage.removeItem('selectedLanguage');
   const fetchCompanyLanguages = async () => {
    let data1; // Move the data declaration outside the try block
    try {
      const url = `${process.env.REACT_APP_BASE_URL_API}company-languages?limit=10&page=1`;
      console.log(url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
          'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
          'WEB-TOKEN' : webToken
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch company languages');
      }

      data1 = await response.json();
        console.log(data1.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  fetchCompanyLanguages();
}
   // Stop refresh page
   useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl key is pressed and the key is 'r'
      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault(); // Prevent the default behavior (refresh)
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  // Show and hide popup
  
  const hideInactivePopup = parseInt(localStorage.getItem('inactivity_popup_dismiss_time')) * 1000;
  const [countdown, setCountdown] = useState(hideInactivePopup / 1000);
    
    const closeModal = (redirect) => {
      setModalOpen(false);
      // setShowPermissionModal(true);
      if (redirect) {
        navigate('/');
      } else {
        setCountdown(0);
      }
    };
  useEffect(() => {
      let timer;
      
      if (modalOpen && countdown > 0) {
        timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000); // decrement countdown every second
      }
  
      return () => {
        clearInterval(timer);
      };
    }, [modalOpen, countdown]);
  
    useEffect(() => {
      if (countdown === 0) {
        closeModal(); // Close modal when countdown reaches 0
      }
    }, [countdown, closeModal]);
  useEffect(() => {
    const showInactivePopup = parseInt(localStorage.getItem('inactivity_popup_show_time')) * 1000;
  
    let inactivityTimer;
    let closeTimer;

    const resetTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
      inactivityTimer = setTimeout(() => {
        setModalOpen(true);
        // setShowPermissionModal(false)
        closeTimer = setTimeout(() => {
          setModalOpen(false);
          setTimeout(() => {
            navigate('/');
          }, 100)
        }, hideInactivePopup);
      }, showInactivePopup);
    };

    const clearTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
    };

    const handleActivity = () => {
      resetTimers();
    };

    // Initial setup
    resetTimers();

    // Event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Clean-up
    return () => {
      clearTimers();
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);
  if (loading) {
    return <div className='h-screen flex items-center justify-center text-3xl text-OyesterGrey'>
       <img src={Loader} className='w-[70px]' alt="Loading..." />
    </div>
  }
  return (
    <div className='screensaver'>
      <Header showBackButton={true} showHomeButton={true} handleGoHome={GoHome} handleClickGoBack={GoBack} />
      <div className='flex items-center justify-center mt-[20px] sm:mt-12'>
        <span className='sm:text-4xl text-xl text-OyesterGrey font-bold'>{t('headings.categoryHeading')}</span>
      </div>
      <div className='flex justify-center flex-wrap gap-3 sm:gap-8 lg:max-w-[824px] xl:max-w-[1280px] mt-[20px] sm:mt-[70px] md:max-w-auto m-auto mb-8'>
        {
          companyCategory.map((catVal) => {
            return (
              <div key={catVal.category_id} onClick={() => handleRedirectClick(catVal.category_id)} data-actiontype={catVal.action_type} data-actionfile={catVal.action_file} className={catactiveId === catVal.category_id ?
                "select-none selectedCat pointer-events-none sm:w-[40%] xl:w-[30%] w-[40%] h-auto sm:min-h-[265px] border border-lightPink sm:rounded-[34px] rounded-[12px] bg-fuchsia cursor-pointer transition-all" :
                "select-none sm:w-[40%] xl:w-[30%] h-auto  w-[40%] sm:min-h-[265px] border border-lightPink hover:bg-pink-100 transition-all sm:rounded-[34px] rounded-[12px] cursor-pointer"}>
                <div className='w-full h-full py-5 sm:py-7 md:py-10  flex items-center justify-center flex-col sm:px-7 text-center break-all'>
                  <img src={catVal.icon} className='sm:w-[96px] sm:h-[96px] w-[40px] h-[40px]' alt={catVal.title} />
                  <p className={`${catactiveId === catVal.category_id ? " text-white" : " text-OyesterGrey"} sm:text-xl xl:text-2xl font-normal mt-[8px] px-[8px] sm:mt-[10px] text-[14px] md:text-xl leading-[normal]`}>
                    {catVal.title}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
      {showPermissionModal && (<PermissionModal handleClick={handleGoClick} closeModal={() => setShowPermissionModal(false)} />)}
      {blockPermissionModal && (<BlockPermissionModal />)}

      <Modal 
        BackToHome={goBackHome} 
        closeModal={() => closeModal(false)} 
        isOpen={modalOpen} 
        popupImage={clockIcon} 
        popupLargeText={t('headings.langPopupLargeText')} 
        popupSmallText={`Continue browsing or be redirected to the home page in ${countdown} seconds.`} 
        btnDarkLabel={t('headings.continueBrowsing')} 
        btnLightLabel={t('headings.returnHome')}>
      </Modal>
    </div>
  )
}

export default SelectCategory