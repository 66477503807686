import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConnectIcon from '../images/connectIcon.svg';
import Button from '../Components/Button';
import Logo from '../Components/Logo';

const Connect = () => {
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const history = useNavigate(); // Import the correct hook name

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsValid(true);
  };

  const handleButtonClick = () => {
    if (inputValue.trim() === '') {
      setIsValid(false);
    } else {
      // If the input is valid, redirect to the Screensaver page
      history('/');
    }
  };

  return (
    <>
      <div className='flex justify-center pt-6 pb-5'>
        <Logo />
      </div>
      <div className="sm:max-w-lg max-w-80 m-auto flex justify-center flex-col connectStyle">
        <div>
          <img src={ConnectIcon} alt="Icon" className='sm:h-40 m-auto mb-2 h-28' />
          <p className='sm:text-3xl text-2xl text-center text-OyesterGrey font-semibold opacity-100'>Uh-oh! Looks like this kiosk isn’t connected yet </p>
          <p className="mt-2 font-normal text-OyesterGrey sm:text-xl text-lg text-center">To keep things moving, you need to connect this kiosk with the Virtual Changi Experience Ambassador (VCEA) system. Please enter a Kiosk ID to begin. </p>
          <div>
            <input type="text" value={inputValue} onChange={handleInputChange} className={`w-full h-[56px] sm:h-[82px] mb-1 mt-10 rounded-2xl border-2 border-style border-[#7D6A551A] px-4 text-2xl text-OyesterGrey transition-shadow focus:border-OyesterGrey outline-none ${isValid ? 'border-[#7D6A551A] ' : 'border-red-500'}`} placeholder='Kiosk id' />
            {!isValid && <p className="text-red-500">Field is required</p>}
            <div className="mt-5 mb-7 text-center">
              <Button label="Connect" onClick={handleButtonClick}></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Connect;
