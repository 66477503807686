import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import quesIcon from '../images/questionIcon.png';
import Modal from '../Components/Modal';
import { useNavigate } from 'react-router-dom';
import clockIcon from '../images/clock.png';
import { useTranslation } from 'react-i18next'; 
import Loader from '../images/loader.gif'

const webToken = localStorage.getItem('uuid');

var data1; 

const SelectLanguage = () => {
 
  const fetchCompanyLanguages = async () => {
    // Move the data declaration outside the try block
    try {
      const url = `${process.env.REACT_APP_BASE_URL_API}company-languages?limit=10&page=1`;
      console.log(url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
          'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
          'WEB-TOKEN' : webToken
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch company languages');
      }

      data1 = await response.json();
        setCompanyLanguages(data1.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Reset language to default language when component mounts
    i18n.changeLanguage('defaultLanguage');
  }, []);

  const [activeId, setActiveId] = useState(() => {
    return localStorage.getItem('selectedLanguage') || 0;
  });


  
  const [modalOpen, setModalOpen] = useState(false);
  const [companyLanguages, setCompanyLanguages] = useState([0]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  
  
  const handleCheckboxClick = async (id) => {
      let data2; // Move the data declaration outside the try block
      try {
        const url1 = `${process.env.REACT_APP_BASE_URL_API}company-languages?limit=10&page=1`;
        console.log(url1);
        const response1 = await fetch(url1, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
            'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
            'WEB-TOKEN' : webToken
          },
        });

        if (!response1.ok) {
          throw new Error('Failed to fetch company languages');
        }

        data2 = await response1.json();
          setCompanyLanguages(data2.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
      fetchCompanyLanguages();
      var languageId42Available =  Array.isArray(data2.data) && data2.data.some(function(item) {
        return item.locale === id;
    });
    console.log('languageId42Available', languageId42Available)
      if (languageId42Available) {
        try {
          const url = `${process.env.REACT_APP_BASE_URL_API}login-register-kiosk`;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
              'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
              'WEB-TOKEN' : webToken
            },
            body: JSON.stringify({
              selectedLanguage: id,  // Pass the selected language to the API
            }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to register language selection');
          }
          const responseData = await response.json();
          console.log('API Response:', responseData);
          localStorage.setItem('token', responseData.data.token);
          localStorage.setItem('user_id', responseData.data.kioskuser.user_id);
          // Update the state or perform any other actions as needed
          setActiveId(id);
          
          // Redirect to the '/category' route after a delay
          localStorage.setItem('selectedLanguage', id);
            i18n.changeLanguage(id);
            setTimeout(() => { 
              navigate(`/${id}/categories`); 
            }, 300);
        } catch (error) {
          console.error('Error making API request:', error);
          // Handle the error as needed
        }
      }
      else {
        setCompanyLanguages([]);
      }
  };

  useEffect(() => {
    // Reset language to default language when component mounts
    i18n.changeLanguage('defaultLanguage');
  }, []);
  useEffect(() => {
    // Update activeId when language changes
    setActiveId(localStorage.getItem('selectedLanguage') || 0);
  }, [i18n.language]);
  

  const HeaderGoHome = () => {
    setTimeout(() => {
      navigate('/');
    }, 50);
  };
  const goBackHome = () => {
    setTimeout(() => {
      navigate('/');
    }, 50);
  };
  // Show and hide popup
  
  const hideInactivePopup = parseInt(localStorage.getItem('inactivity_popup_dismiss_time')) * 1000;
  const [countdown, setCountdown] = useState(hideInactivePopup / 1000);
    
    const closeModal = (redirect) => {
      setModalOpen(false);
      if (redirect) {
        navigate('/');
      } else {
        if (!companyLanguages.length) {
          fetchCompanyLanguages();
        }

        setCountdown(0);
      }
    };
  useEffect(() => {
      let timer;
      
      if (modalOpen && countdown > 0) {
        timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000); // decrement countdown every second
      }
  
      return () => {
        clearInterval(timer);
      };
    }, [modalOpen, countdown]);
  
    useEffect(() => {
      if (countdown === 0) {
        closeModal(); // Close modal when countdown reaches 0
      }
    }, [countdown, closeModal]);
  useEffect(() => {
    const showInactivePopup = parseInt(localStorage.getItem('inactivity_popup_show_time')) * 1000;
  
    let inactivityTimer;
    let closeTimer;

    const resetTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
      inactivityTimer = setTimeout(() => {
        setModalOpen(true);
        closeTimer = setTimeout(() => {
          setModalOpen(false);
          setTimeout(() => {
            navigate('/');
          }, 500)
        }, hideInactivePopup);
      }, showInactivePopup);
    };

    const clearTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
    };

    const handleActivity = () => {
      resetTimers();
    };

    // Initial setup
    resetTimers();

    // Event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Clean-up
    return () => {
      clearTimers();
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  useEffect(() => {
    fetchCompanyLanguages();
    // localStorage.clear();
  }, []);

  //Stop refresh page
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl key is pressed and the key is 'r'
      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault(); // Prevent the default behavior (refresh)
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleMouseLeave = () => {
      document.body.style.cursor = 'not-allowed'; // Change cursor style to 'not-allowed'
    };

    const handleMouseEnter = () => {
      document.body.style.cursor = 'auto'; // Reset cursor style to default
    };

    // Add event listeners on component mount
    window.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('mouseenter', handleMouseEnter);

    // Clean up event listeners on component unmount-
    return () => {
      window.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('mouseenter', handleMouseEnter);
    };
  }, []);

  if (loading) {
    return <div className='h-screen flex items-center justify-center text-3xl text-OyesterGrey'>
      <img src={Loader} className='w-[70px]' alt="Loading..." />
    </div>
  }
  if (companyLanguages.length === 0) {
    return  <div className='h-screen'> <Header showBackButton={false} showHomeButton={false} handleOpenModal={HeaderGoHome} /> <div className='thankYou flex items-center justify-center text-[40px] px-[20px] sm:text-[50px] text-OyesterGrey text-center'>No advisor available</div></div>;
  }

  return (
    <div className='select-language'>
      {/* <Header showBackButton={false} handleGoHome={HeaderGoHome} showHomeButton={true} /> */}
      <Header
  showBackButton={false}
  handleGoHome={HeaderGoHome}
  showHomeButton={true}
  langCode={localStorage.getItem('selectedLanguage')} // Pass the language code here
/>
      <div className='language flex items-center xl:justify-center justify-start flex-col mt-[30px] sm:mt-[50px] xl:mt-[48px]'>
        <div className='flex items-start justify-center'>
          <img src={quesIcon} alt='Question Icon' className='sm:w-[78px] sm:h-[78px] w-[40px] h-[40px] mr-2 sm:mr-5' />
          <h2 className='sm:text-4xl text-xl mt-0 sm:mt-[10px] text-OyesterGrey font-bold'>
            {t('headings.langHeading')}
            </h2>
        </div>
        <div className='flex flex-wrap sm:gap-12 gap-3 w-[80%] max-w-full md:max-w-[800px] lg:max-w-[824px] xl:max-w-[1280px] sm:max-w-[100%] justify-center mt-[30px] sm:mt-[30px] mx-auto mb-8 sm:px-7 lg:px-0'>
        {companyLanguages.map((val) => {
          const className = activeId === val.locale
            ? 'select-none sm:w-[200px] md:w-[250px] w-[46%] text-center selectedLanguage pointer-events-none h-[90px] rounded-[12px] px-[8px] lg:h-[186px] sm:h-[150px] sm:w-[44%] sm:text-2xl sm:rounded-[16px] border flex items-center cursor-pointer justify-center md:rounded-[30px] text-white uppercase lg:text-[32px] bg-fuchsia transition-all text-[14px]'
            : 'select-none sm:w-[200px] md:w-[250px] w-[46%] text-center h-[90px] rounded-[12px] lg:h-[186px] sm:h-[150px] sm:text-2xl px-[8px] sm:rounded-[16px] border flex items-center cursor-pointer justify-center transition-all md:rounded-[30px] border-lightPink hover:bg-pink-100 text-themeGrey uppercase lg:text-[32px] px-[8px] text-[14px]';
          return (
            <div onClick={() => handleCheckboxClick(val.locale)} className={className} key={val.locale}>
              {val.native_name}
            </div>
          );
        })}
        </div>
      </div>
      <Modal 
        BackToHome={goBackHome} 
        closeModal={() => closeModal(false)} 
        isOpen={modalOpen} 
        popupImage={clockIcon} 
        popupLargeText={t('headings.langPopupLargeText')} 
        popupSmallText={`Continue browsing or be redirected to the home page in ${countdown} seconds.`} 
        btnDarkLabel={t('headings.continueBrowsing')} 
        btnLightLabel={t('headings.returnHome')}>
      </Modal>
    </div>
  );
};

export default SelectLanguage